// Toasts

$toast-max-width:                350px !default;
$toast-padding-x:                .75rem !default;
$toast-padding-y:                .25rem !default;
$toast-font-size:                .875rem !default;
$toast-border-width:             1px !default;
$toast-border-radius:            .25rem !default;
$toast-box-shadow:               0 .25rem .75rem rgba($black, .1) !default;

// Default theme
$toast-background-color:  rgba($white, .85) !default;
$toast-color:             null !default;
$toast-border-color:      rgba($black, .1) !default;

$toast-header-color:             $gray-600 !default;
$toast-header-background-color:  rgba($white, .85) !default;
$toast-header-border-color:      rgba($black, .05) !default;

$toast-theme-map: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$toast-theme-map: map-merge(
  (
    default: (
      "toast-background-color":         $toast-background-color,
      "toast-color":                    $toast-color,
      "toast-border-color":             $toast-border-color,
      "toast-header-color":             $toast-header-color,
      "toast-header-background-color":  $toast-header-background-color,
      "toast-header-border-color":      $toast-header-border-color
    )
  ),
  $toast-theme-map
);
