// Modals

// Padding applied to the modal body
$modal-inner-padding: 1rem !default;
// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between:  .5rem !default;

$modal-dialog-margin:          .5rem !default;
$modal-dialog-margin-y-sm-up:  1.75rem !default;

$modal-title-line-height:  $line-height-base !default;

$modal-content-border-width:         $border-width !default;
$modal-content-border-radius:        $border-radius-lg !default;
$modal-content-inner-border-radius:  subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs:        0 .25rem .5rem rgba($black, .5) !default;
$modal-content-box-shadow-sm-up:     0 .5rem 1rem rgba($black, .5) !default;

$modal-backdrop-bg:          $black !default;
$modal-backdrop-opacity:     .5 !default;
$modal-header-padding-y:     1rem !default;
$modal-header-padding-x:     1rem !default;
$modal-header-padding:       $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility
$modal-header-border-width:  $modal-content-border-width !default;
$modal-footer-border-width:  $modal-header-border-width !default;

$modal-xl:  1140px !default;
$modal-lg:  800px !default;
$modal-md:  500px !default;
$modal-sm:  300px !default;

$modal-fade-transform:   translate(0, -50px) !default;
$modal-show-transform:   none !default;
$modal-transition:       transform .3s ease-out !default;
$modal-scale-transform:  scale(1.02) !default;

// Default theme
$modal-content-color:         null !default;
$modal-content-bg:            $white !default;
$modal-content-border-color:  rgba($black, .2) !default;
$modal-header-border-color:   $border-color !default;
$modal-footer-border-color:   $modal-header-border-color !default;

$modal-theme-map: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$modal-theme-map: map-merge(
  (
    default: (
      "modal-content-color":         $modal-content-color,
      "modal-content-bg":            $modal-content-bg,
      "modal-content-border-color":  $modal-content-border-color,
      "modal-header-border-color":   $modal-header-border-color,
      "modal-footer-border-color":   $modal-footer-border-color,
    )
  ),
  $modal-theme-map
);
