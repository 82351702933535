

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:         .75rem !default;
$alert-padding-x:         1.25rem !default;
$alert-margin-bottom:     1rem !default;
$alert-border-radius:     $border-radius !default;
$alert-link-font-weight:  $font-weight-bold !default;
$alert-border-width:      $border-width !default;

$alert-bg-level:      -10 !default;
$alert-border-level:  -9 !default;
$alert-color-level:   6 !default;
