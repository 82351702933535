// Switches

$switch-width:      40px !default;
$switch-height:     26px !default;
$switch-font-size:  10px !default;

$switch-lg-width:      48px !default;
$switch-lg-height:     30px !default;
$switch-lg-font-size:  12px !default;

$switch-sm-width:      32px !default;
$switch-sm-height:     22px !default;
$switch-sm-font-size:  8px !default;

$switch-label-width:     48px !default;
$switch-label-lg-width:  56px !default;
$switch-label-sm-width:  40px !default;

$switch-handle-margin:  2px !default;
