$custom-forms-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$custom-control-gutter:    .5rem !default;
$custom-control-spacer-x:  1rem !default;
$custom-control-cursor:    null !default;

$custom-control-indicator-size:  1rem !default;

$custom-control-indicator-bg-size:       50% 50% !default;
$custom-control-indicator-box-shadow:    $input-box-shadow !default;
$custom-control-indicator-border-width:  $input-border-width !default;

$custom-control-label-color:  null !default;

$custom-control-indicator-checked-box-shadow:    none !default;

$custom-control-indicator-focus-box-shadow:    $input-focus-box-shadow !default;

$custom-control-indicator-active-box-shadow:    none !default;

$custom-checkbox-indicator-border-radius:  $border-radius !default;

$custom-checkbox-indicator-indeterminate-box-shadow:    none !default;

$custom-radio-indicator-border-radius:  50% !default;

$custom-switch-width:                    $custom-control-indicator-size * 1.75 !default;
$custom-switch-indicator-border-radius:  $custom-control-indicator-size / 2 !default;
$custom-switch-indicator-size:           subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4) !default;

$custom-select-padding-y:          $input-padding-y !default;
$custom-select-padding-x:          $input-padding-x !default;
$custom-select-font-family:        $input-font-family !default;
$custom-select-font-size:          $input-font-size !default;
$custom-select-height:             $input-height !default;
$custom-select-indicator-padding:  1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight:        $input-font-weight !default;
$custom-select-line-height:        $input-line-height !default;

$custom-select-bg-size:            8px 10px !default; // In pixels because image dimensions

$custom-select-feedback-icon-padding-right:  add(1em * .75, (2 * $custom-select-padding-y * .75) + $custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-position:       center right ($custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-size:           $input-height-inner-half $input-height-inner-half !default;

$custom-select-border-width:   $input-border-width !default;
$custom-select-border-radius:  $border-radius !default;
$custom-select-box-shadow:     inset 0 1px 2px rgba($black, .075) !default;

$custom-select-focus-width:         $input-focus-width !default;
$custom-select-focus-box-shadow:    0 0 0 $custom-select-focus-width $input-btn-focus-color !default;

$custom-select-padding-y-sm:  $input-padding-y-sm !default;
$custom-select-padding-x-sm:  $input-padding-x-sm !default;
$custom-select-font-size-sm:  $input-font-size-sm !default;
$custom-select-height-sm:     $input-height-sm !default;

$custom-select-padding-y-lg:  $input-padding-y-lg !default;
$custom-select-padding-x-lg:  $input-padding-x-lg !default;
$custom-select-font-size-lg:  $input-font-size-lg !default;
$custom-select-height-lg:     $input-height-lg !default;

$custom-range-track-width:          100% !default;
$custom-range-track-height:         .5rem !default;
$custom-range-track-cursor:         pointer !default;
$custom-range-track-border-radius:  1rem !default;
$custom-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1) !default;

$custom-range-thumb-width:                   1rem !default;
$custom-range-thumb-height:                  $custom-range-thumb-width !default;
$custom-range-thumb-border:                  0 !default;
$custom-range-thumb-border-radius:           1rem !default;
$custom-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1) !default;
$custom-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow !default;
$custom-range-thumb-focus-box-shadow-width:  $input-focus-width !default; // For focus box shadow issue in IE/Edge

$custom-file-height:              $input-height !default;
$custom-file-height-inner:        $input-height-inner !default;
$custom-file-focus-box-shadow:    $input-focus-box-shadow !default;

$custom-file-padding-y:      $input-padding-y !default;
$custom-file-padding-x:      $input-padding-x !default;
$custom-file-line-height:    $input-line-height !default;
$custom-file-font-family:    $input-font-family !default;
$custom-file-font-weight:    $input-font-weight !default;

$custom-file-border-width:   $input-border-width !default;

$custom-file-border-radius:  $input-border-radius !default;
$custom-file-box-shadow:     $input-box-shadow !default;
$custom-file-text: (
  en: "Browse"
) !default;


// Default Theme

$custom-control-indicator-bg:            $input-bg !default;
$custom-control-indicator-border-color:  $gray-500 !default;
$custom-control-indicator-disabled-bg:   $input-disabled-bg !default;

$custom-control-label-disabled-color:  $gray-600 !default;

$custom-control-indicator-checked-color:         $component-active-color !default;
$custom-control-indicator-checked-bg:            $component-active-bg !default;
$custom-control-indicator-checked-disabled-bg:   rgba(theme-color("primary"), .5) !default;
$custom-control-indicator-checked-border-color:  $custom-control-indicator-checked-bg !default;

$custom-control-indicator-focus-border-color:  $input-focus-border-color !default;

$custom-control-indicator-active-color:         $component-active-color !default;
$custom-control-indicator-active-bg:            lighten($component-active-bg, 35%) !default;
$custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg !default;

$custom-checkbox-indicator-icon-checked:                url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>") !default;
$custom-checkbox-indicator-indeterminate-bg:            $component-active-bg !default;
$custom-checkbox-indicator-indeterminate-color:         $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-indeterminate-border-color:  $custom-checkbox-indicator-indeterminate-bg !default;
$custom-checkbox-indicator-icon-indeterminate:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>") !default;

$custom-radio-indicator-icon-checked:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>") !default;

$custom-select-color:               $input-color !default;
$custom-select-disabled-color:      $gray-600 !default;
$custom-select-bg:                  $input-bg !default;
$custom-select-disabled-bg:         $gray-200 !default;
$custom-select-indicator-color:     $gray-800 !default;
$custom-select-indicator:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") !default;
$custom-select-background:          escape-svg($custom-select-indicator) no-repeat right $custom-select-padding-x center / $custom-select-bg-size !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)
$custom-select-border-color:        $input-border-color !default;
$custom-select-focus-border-color:  $input-focus-border-color !default;

$custom-range-track-bg:           $gray-300 !default;
$custom-range-thumb-bg:           $component-active-bg !default;
$custom-range-thumb-active-bg:    lighten($component-active-bg, 35%) !default;
$custom-range-thumb-disabled-bg:  $gray-500 !default;

$custom-file-focus-border-color:  $input-focus-border-color !default;
$custom-file-disabled-bg:         $input-disabled-bg !default;
$custom-file-color:               $input-color !default;
$custom-file-bg:                  $input-bg !default;
$custom-file-border-color:        $input-border-color !default;
$custom-file-button-color:        $custom-file-color !default;
$custom-file-button-bg:           $input-group-addon-bg !default;


$custom-forms-theme-map: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$custom-forms-theme-map: map-merge(
  (
    default: (
      "custom-control-indicator-bg":                           $custom-control-indicator-bg,
      "custom-control-indicator-border-color":                 $custom-control-indicator-border-color,
      "custom-control-indicator-disabled-bg":                  $custom-control-indicator-disabled-bg,
      "custom-control-label-disabled-color":                   $custom-control-label-disabled-color,
      "custom-control-indicator-checked-color":                $custom-control-indicator-checked-color,
      "custom-control-indicator-checked-bg":                   $custom-control-indicator-checked-bg,
      "custom-control-indicator-checked-disabled-bg":          $custom-control-indicator-checked-disabled-bg,
      "custom-control-indicator-checked-border-color":         $custom-control-indicator-checked-border-color,
      "custom-control-indicator-focus-border-color":           $custom-control-indicator-focus-border-color,
      "custom-control-indicator-active-color":                 $custom-control-indicator-active-color,
      "custom-control-indicator-active-bg":                    $custom-control-indicator-active-bg,
      "custom-control-indicator-active-border-color":          $custom-control-indicator-active-border-color,
      "custom-checkbox-indicator-icon-checked":                $custom-checkbox-indicator-icon-checked,
      "custom-checkbox-indicator-indeterminate-bg":            $custom-checkbox-indicator-indeterminate-bg,
      "custom-checkbox-indicator-indeterminate-color":         $custom-checkbox-indicator-indeterminate-color,
      "custom-checkbox-indicator-indeterminate-border-color":  $custom-checkbox-indicator-indeterminate-border-color,
      "custom-checkbox-indicator-icon-indeterminate":          $custom-checkbox-indicator-icon-indeterminate,
      "custom-radio-indicator-icon-checked":                   $custom-radio-indicator-icon-checked,
      "custom-select-color":                                   $custom-select-color,
      "custom-select-disabled-color":                          $custom-select-disabled-color,
      "custom-select-bg":                                      $custom-select-bg,
      "custom-select-disabled-bg":                             $custom-select-disabled-bg,
      "custom-select-indicator-color":                         $custom-select-indicator-color,
      "custom-select-indicator":                               $custom-select-indicator,
      "custom-select-background":                              $custom-select-background,
      "custom-select-border-color":                            $custom-select-border-color,
      "custom-select-focus-border-color":                      $custom-select-focus-border-color,
      "custom-range-track-bg":                                 $custom-range-track-bg,
      "custom-range-thumb-bg":                                 $custom-range-thumb-bg,
      "custom-range-thumb-active-bg":                          $custom-range-thumb-active-bg,
      "custom-range-thumb-disabled-bg":                        $custom-range-thumb-disabled-bg,
      "custom-file-focus-border-color":                        $custom-file-focus-border-color,
      "custom-file-disabled-bg":                               $custom-file-disabled-bg,
      "custom-file-color":                                     $custom-file-color,
      "custom-file-bg":                                        $custom-file-bg,
      "custom-file-border-color":                              $custom-file-border-color,
      "custom-file-button-color":                              $custom-file-button-color,
      "custom-file-button-bg":                                 $custom-file-button-bg
    )
  ),
  $custom-forms-theme-map
);
